const toggleNavigationMainMobileMenu: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const mainNavigation: any = document.getElementById('navigationMain');
    const corporateNavigation: any = mainNavigation.getElementsByClassName('corporate')[0];
    const brokerNavigation: any = mainNavigation.getElementsByClassName('broker')[0];

    if (mainNavigation.classList.contains('corporate')) {
      corporateNavigation.classList.toggle('active');
      brokerNavigation.classList.remove('active');
    }

    if (mainNavigation.classList.contains('broker')) {
      brokerNavigation.classList.toggle('active');
      corporateNavigation.classList.remove('active');
    }
  } catch (error) {
    console.error(error);
  }
};

const resetNavigationMainMobileMenu: () => void = () => {
  try {
    const mainNavigation: any = document.getElementById('navigationMain');
    const corporateNavigation: any = mainNavigation.getElementsByClassName('corporate')[0];
    const brokerNavigation: any = mainNavigation.getElementsByClassName('broker')[0];

    corporateNavigation.classList.remove('active');
    brokerNavigation.classList.remove('active');
  } catch (error) {
    console.error(error);
  }
};

window.addEventListener('resize', () => {
  resetNavigationMainMobileMenu();
});
